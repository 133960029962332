import * as React from "react"

function SvgWordmark(props) {
  return (
    <svg width={264} height={23} {...props}>
      <defs>
        <path id="wordmark_svg__a" d="M.033.4h21.222V22H.033z" />
        <path id="wordmark_svg__c" d="M.009.4h20.816V22H.01z" />
        <path id="wordmark_svg__e" d="M0 22h263.19V.4H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#E6E1DA"
          d="M0 21.87h4v-21H0zM35 .87v3.98h6.241v17.02h4.518V4.85H52V.87zM56 .87v21h16v-3.978H60.593v-4.586h11.074v-3.98H60.593v-4.45H71.86V.872z"
        />
        <g transform="translate(0 .87)">
          <g transform="translate(97.888)">
            <mask id="wordmark_svg__b" fill="#fff">
              <use xlinkHref="#wordmark_svg__a" />
            </mask>
            <path
              d="M9.186 9.576v3.823h7.587c-.702 2.73-2.834 4.505-5.86 4.505-3.644 0-6.398-2.894-6.398-6.69 0-3.768 2.7-6.69 6.238-6.69 1.849 0 3.58.653 4.804 2.294h5.05C19.205 2.722 15.262.4 10.754.4 4.73.4.033 5.152.033 11.215.033 17.275 4.758 22 10.833 22c6.102 0 10.422-4.56 10.422-10.758 0-.464-.026-1.011-.107-1.666H9.186z"
              fill="#E6E1DA"
              mask="url(#wordmark_svg__b)"
            />
          </g>
          <path
            d="M129.114 14.163l3.024-6.8 2.997 6.8h-6.021zM130.626.81l-9.666 20.753h4.86l1.566-3.523h9.45l1.567 3.523h4.86L133.596.81h-2.97zM151.568.81h-4.455v20.753h12.851l1.831-3.932h-10.227zM172.446 14.163l3.025-6.8 2.996 6.8h-6.02zM173.958.81l-9.666 20.753h4.86l1.567-3.523h9.45l1.566 3.523h4.86L176.929.81h-2.97z"
            fill="#E6E1DA"
          />
          <g transform="translate(187.865)">
            <mask id="wordmark_svg__d" fill="#fff">
              <use xlinkHref="#wordmark_svg__c" />
            </mask>
            <path
              d="M10.701 4.523c2.445 0 4.505 1.322 5.477 3.77h4.647C19.692 3.512 15.642.398 10.701.398 4.708.4.01 5.151.01 11.214.009 17.275 4.708 22 10.701 22c4.941 0 9.018-3.085 10.124-8.056h-4.572c-.937 2.587-3.042 3.96-5.552 3.96-3.536 0-6.237-2.895-6.237-6.69 0-3.796 2.7-6.69 6.237-6.69"
              fill="#E6E1DA"
              mask="url(#wordmark_svg__d)"
            />
          </g>
          <path
            fill="#E6E1DA"
            d="M211.719 4.742h6.156v16.821h4.454V4.743h6.156V.81H211.72z"
          />
          <mask id="wordmark_svg__f" fill="#fff">
            <use xlinkHref="#wordmark_svg__e" />
          </mask>
          <path
            fill="#E6E1DA"
            mask="url(#wordmark_svg__f)"
            d="M233.012 21.563h4.456V.81h-4.456zM253.065 17.904c-3.536 0-6.237-2.895-6.237-6.69 0-3.796 2.7-6.69 6.237-6.69 2.446 0 4.505 1.322 5.477 3.768h4.648C262.057 3.512 258.006.4 253.065.4c-5.993 0-10.691 4.752-10.691 10.815 0 6.06 4.698 10.785 10.691 10.785 4.941 0 9.018-3.085 10.125-8.055h-4.572c-.937 2.587-3.043 3.959-5.553 3.959M25.787 13.78L13.583.81h-2.81v20.754h4.456V8.893l12.204 12.67h2.783V.81h-4.43zM82.117 9.384V4.66h4.833c1.916 0 2.915 1.037 2.915 2.35 0 1.336-1 2.374-2.915 2.374h-4.833zM94.24 7.01c0-3.633-2.7-6.199-7.075-6.199h-9.503v20.753h4.455v-8.329h3.207l6.157 8.33h5l-6.62-8.752c2.752-.842 4.379-2.996 4.379-5.803z"
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgWordmark
